import {useEffect} from 'react';
import {Field} from "redux-form";
import TableFormule from "./TableFormule/TableFormule";
import _ from "lodash";
import BlocFranchises from "./BlocFranchises/BlocFranchises";
import BlocOption from "./BlocOption/BlocOption";
import SlideContainer from "../../Slide/SlideContainer.js";
import {required} from "@amo/core/utils/validateField";
import {Text} from "@amo/core/components/forms"
import {QuestionLabel, Icons, Multidetention} from "@amo/core/components/containers";
import {type} from "@testing-library/user-event/dist/type/index.js";


const Tarif = (props) => {
    const {source, isConcess, radioGroupSelectedASS, radioGroupSelectedPCC, radioGroupSelectedACCESSEQP, radioGroupSelectedKSM, radioGroupSelectedVALMAJ, listeFormules, formulePreconisee, formuleSelectionnee, fractionnement, changeValue,
        franchises, garantiesSelected, garantiesASS, garantiesPCC, garantiesKSM, garantiesVALMAJ, garantiesACCESSEQP,
        reloadTarif, codePromo,codePromoInForm, nbMoisAssuranceMoto, nbMoisAssuranceAuto, valeurCrmMoto, valeurCrmAuto, infoDTA, infoVI,
        selectedFranchiseVI, selectedFranchiseDTA, handleSubmit, previousStep, tarifError, setDevisOrSubscribe, nextStep, honoraires, tauxApporteur1, fraisReccurent,
        tauxApporteurMax, honorairesMax, fraisRecurrentsMax, has_frais, needCommentaire, typeVehicule, vehicule, data
    } = props
    const formule = _.find(listeFormules, ['CodeFormule', formuleSelectionnee])
    const categories = ['CUS','TRA','RTS','GRT','RBA','ROA','SPO'];

    const changeGarantiesSelected = () => {
        let options = {};
        let optionsFranchise = {};
        let optionsListe = {};

        _.map(garantiesSelected, g => {
            const id = g.IdGroupeGarantie

            if ((id === 'RAFRAVI' || id === 'RAFRADTA')) {
                optionsFranchise[g.CodeGarantie] = true;
            } else if(g.listeTarifOption !== undefined && g.DetailOption !== undefined && id === 'ACCESSEQP'){
                optionsListe[id] = g.DetailOption;
            } else {
                options[id] = g.CodeGarantie;
            }
            changeValue(`options`, options);
            changeValue(`optionsFranchise`, optionsFranchise);
            changeValue(`optionsListe`, optionsListe);
        })
    }

    useEffect(() => {
        //On vérifie si on a une formule sélectionnée et si celle ci est toujours dispo
        if(formulePreconisee && (!formuleSelectionnee || !_.find(listeFormules, { 'CodeFormule': formuleSelectionnee }))){
            changeValue('DemandeTarif[Police][FormuleChoisie]', formulePreconisee);
        }

    }, [formulePreconisee])

    useEffect(() => {
        if(garantiesSelected){
            changeGarantiesSelected()
        }

    }, [garantiesSelected])

    useEffect(() => {
        changeValue('DemandeDevis[TypeMER]', 'DemandeDevis')
        if((nbMoisAssuranceAuto === undefined && valeurCrmAuto !== undefined)){
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', "1.00")
        }
        if((nbMoisAssuranceMoto === undefined && valeurCrmMoto !== undefined)){
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', "1.00")
        }

    }, [])

    useEffect(() => {
        changeValue('DemandeTarif[Police][TauxApporteur1]', tauxApporteur1)
        changeValue('DemandeTarif[Police][Honoraires]', honoraires)
        changeValue('DemandeTarif[Police][FraisRecurrents]', fraisReccurent)

    }, [])

    const goDevisOrSubscribe = (form, state) => {
        setDevisOrSubscribe({state})
        nextStep(form)
    }

    return (
        <div>
            <TableFormule listeFormules={listeFormules} formulePreconisee={formulePreconisee} fractionnement={fractionnement} formuleSelectionnee={formuleSelectionnee}/>
            {
                franchises.labelGroupe ?
                    <>
                        <h1 className={'h4 text-center mt-4 mb-3'}>Voici le détail du montant de vos franchises</h1>
                        <div className={'mb-3 mt-mb-4'}>
                            <BlocFranchises franchises={franchises} fractionnement={fractionnement} infoDTA={infoDTA} infoVI={infoVI} selectedFranchiseVI={selectedFranchiseVI} selectedFranchiseDTA={selectedFranchiseDTA}/>
                        </div>
                    </>
                    :
                    infoVI[0] && infoDTA[0] ?
                        (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible') &&
                        <>
                            <h1 className={'h4 text-center mt-4 mb-3'}>Voici le détail du montant de vos franchises</h1>
                            <div className={'tarifOption border rounded h-100 position-relative mb-footer'}>
                                <div className={'p-4 d-flex align-items-center bg-grey-light text-initial'}>
                                    {(infoVI[0] && infoVI[0].Disponible !== 'NonDisponible') && <p><strong>Franchise Vol/Incendie :</strong> {infoVI[0].ConditionsIndemnisation}</p>}
                                    {(infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible') && <p><strong>Franchise DTA :</strong> {infoDTA[0].ConditionsIndemnisation}</p>}
                            </div>
                            </div>
                        </> : ''
            }

            <h1 className={'h4 text-center mt-4 mb-3'}>Nous vous conseillons d'améliorer votre couverture<br/>avec des options sur-mesure</h1>

            <div className={'row'}>
                {
                    garantiesASS.labelGroupe &&
                    <BlocOption option={garantiesASS} icon={'truck'} selected={radioGroupSelectedASS} fractionnement={fractionnement}/>
                }
                {
                    garantiesPCC.labelGroupe &&
                    <BlocOption option={garantiesPCC} icon={'bicker'} selected={radioGroupSelectedPCC} fractionnement={fractionnement}/>
                }

                {
                    garantiesACCESSEQP.labelGroupe &&
                    <BlocOption option={garantiesACCESSEQP} icon={'garanty'} selected={radioGroupSelectedACCESSEQP} fractionnement={fractionnement}/>
                }

                {
                    garantiesKSM.labelGroupe &&
                    <BlocOption option={garantiesKSM} icon={'tool'} selected={radioGroupSelectedKSM} fractionnement={fractionnement}/>
                }
                {
                    garantiesVALMAJ.labelGroupe &&
                    <BlocOption option={garantiesVALMAJ} icon={'money'} selected={radioGroupSelectedVALMAJ} colLg={''} fractionnement={fractionnement}/>
                }

                <QuestionLabel className={'col-12 f-14 text-center'}>Avez vous un code promotionnel?</QuestionLabel>

                <div className={'col-12 offset-md-2 col-md-4 my-3'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[CodePromo][CodePromo]'}
                    />
                </div>

                <div className={'col-12 col-md-4 my-3'}>
                    <button
                        className={`${!codePromoInForm && 'disabled'} btn btn-primary medium-rounded w-100`}
                        onClick={!codePromoInForm ? null : reloadTarif}>Valider</button>
                </div>
            </div>

            {
                codePromo && codePromo.Eligible && codePromo.MessageEligible &&
                <div className={'font-weight-bold text-success text-center'}>
                    <Icons className={`checkSuccess border full-rounded border-success d-inline-block position-relative align-middle mr-2 p-2`} fontSize={7} icon={'Shape'}/>
                    {codePromo.MessageEligible}
                </div>
            }
            {
                codePromo && !codePromo.Eligible && codePromo.MessageEligible &&
                <div className={'font-weight-bold text-error text-center'}>
                    {codePromo.MessageEligible}
                </div>
            }

            {needCommentaire && (
                <div className={'row'}>
                    <QuestionLabel className={'col-12 f-14 text-center'}>Concurrent</QuestionLabel>
                    <div className={'col-12 offset-md-2 col-md-8 my-3 mt-0'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[CodePromo][Concurrent]'}
                        />
                    </div>

                    <QuestionLabel className={'col-12 f-14 text-center mt-0'}>Prime concurrent</QuestionLabel>
                    <div className={'col-12 offset-md-2 col-md-8 my-3'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[CodePromo][PrimeConcurrent]'}
                        />
                    </div>

                    <QuestionLabel className={'col-12 f-14 text-center mt-0'}>Commentaire</QuestionLabel>
                    <div className={'col-12 offset-md-2 col-md-8 my-3'}>
                        <Field
                            component={Text}
                            name={'DemandeTarif[CodePromo][CommentaireConcurrent]'}
                        />
                    </div>
                </div>
            )}

            {
                (!isConcess && source !== 'site') && <>
                    <QuestionLabel className={'f-16 mt-4 text-center col-12'} required>Votre taux de commission</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8 col-md-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[Police][TauxApporteur1]'}
                                step={1}
                                min={0}
                                max={tauxApporteurMax}
                                validate={required}
                                maxBackground={'bg-success'}
                                minBackground={'bg-success'}
                                minColor={'text-white'}
                                maxColor={'text-white'}
                                symbole={'%'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Police][TauxApporteur1]'}
                                type={'number'}
                                max={tauxApporteurMax}
                            />
                        </div>
                    </div>

                    { formule && formule.MontantCommApporteurReglette && formule.MontantCommApporteurReglette > 0 && <div className="row">
                        <div className="col mt-3">
                            <p>Votre montant de commission : <b>{formule.MontantCommApporteurReglette} €</b></p>
                            <p><i>Si vous modifiez les options ou le taux de commission, merci d'actualiser le montant en cliquant sur Recalculer le tarif.</i></p>
                        </div>
                    </div> }

                    <QuestionLabel required className={'f-16 mt-4 text-center col-12'}>Vos frais de dossier</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[Police][Honoraires]'}
                                step={1}
                                min={0}
                                max={honorairesMax}
                                validate={required}
                                maxBackground={'bg-success'}
                                minBackground={'bg-success'}
                                minColor={'text-white'}
                                maxColor={'text-white'}
                                symbole={'€'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Police][Honoraires]'}
                                type={'number'}
                                max={honorairesMax}
                            />
                        </div>
                    </div>

                    {has_frais && <>
                        <QuestionLabel className={'f-16 mt-4 text-center col-12'} required>Frais récurrents</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8'}>
                                <Field
                                    component={SlideContainer}
                                    name={'DemandeTarif[Police][FraisRecurrents]'}
                                    step={1}
                                    min={0}
                                    max={fraisRecurrentsMax}
                                    validate={required}
                                    maxBackground={'bg-success'}
                                    minBackground={'bg-success'}
                                    minColor={'text-white'}
                                    maxColor={'text-white'}
                                    symbole={'€'}
                                />
                            </div>
                            <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[Police][FraisRecurrents]'}
                                    type={'number'}
                                    max={fraisRecurrentsMax}
                                />
                            </div>
                        </div>
                    </>}

                    <div className={'mt-3 text-center'}>
                        <button className={`btn text-primary font-weight-bolder`} onClick={reloadTarif} data-id="btn-devis">Recalculer le tarif</button>
                    </div>
                </>
            }

            {typeVehicule === 'moto' && _.includes(categories, vehicule.codecategorie) && (
                <div id={'souscrire'} className={'container '}>
                    <div className={`row mt-4`}>
                        <div className={`rounded text-center col-12 multidetention`}>
                            {(_.get(data, 'DemandeTarif[Vehicule][EligibleMultidetention]') === 'true' || _.get(data, 'DemandeTarif[Vehicule][EligibleMultidetention]') === true) && (
                                <Multidetention {...props} courtier={true}/>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {
                !tarifError && <div className={'mt-5 submit-tarif text-center'}>
                    <button className={`btn btn-outline-primary medium-rounded text-uppercase m-md-2`}
                            onClick={handleSubmit(form => goDevisOrSubscribe(form, 'devis'))}
                            data-id="btn-devis">Obtenir la proposition
                    </button>
                    <button className={`btn btn-primary medium-rounded text-uppercase pl-5 pr-5`}
                            onClick={handleSubmit(form => goDevisOrSubscribe(form, 'subscribe'))}>Souscrire
                    </button>
                </div>
            }

            <div className={'submit-row mt-5'}>
                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>Retour</button>
            </div>
        </div>
    );
};


Tarif.defaultProps = {};


Tarif.propTypes = {};


export default Tarif;
