import { QuestionLabel } from '@amo/core/components/containers';
import {Autocomplete, AutoCompleteBirth, Radio, Text} from '@amo/core/components/forms';
import { getCountries } from '@amo/core/utils/requestsApi.js';
import {minLentghTwo, required} from '@amo/core/utils/validateField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import questions from '../../../../../../../utils/json/questions.json';
import _ from 'lodash'

const PersonneFields = (props) => {
    const { personneIndex, changeValue, unsetValue, countries, setCountries, source, birthCountry, data } = props;
    const [nomUsage, setNomUsage] = useState('');

    useEffect(() => {
        (async function fetchCountries() {
            let countries = await getCountries();
            setCountries({ countries });
        })();
    }, []);

    const handleName = (e) => {
        changeValue(`DemandeTarif[ListePersonnes][${personneIndex}][BirthName]`, e.target.value.toUpperCase());
    };

    return (
        <>
            <QuestionLabel className={'f-14 mt-4'} required>
                Civilité&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Civilite]`}
                        value={'1'}
                        label={'Madame'}
                        className={'small'}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Civilite]`}
                        value={'0'}
                        label={'Monsieur'}
                        className={'small'}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                Nom d'usage&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Nom]`}
                        normalize={(v) => {
                            setNomUsage(v.toUpperCase());
                            return v.toUpperCase();
                        }}
                        validate={required}
                        onChange={(e) => handleName(e)}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                Nom de naissance&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthName]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                        placeholder={nomUsage}
                    />
                    <p className="f-14 text-warning">{questions['nomNaissanceDiffNomUsage'][source]}</p>
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                Prénom&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][Prenom]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={required}
                    />
                </div>
            </div>

            {
                (
                    data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom &&
                    (
                        data?.DemandeTarif?.ListePersonnes[personneIndex]?.Nom === data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom ||
                        data?.DemandeTarif?.ListePersonnes[personneIndex]?.BirthName === data?.DemandeTarif?.ListePersonnes[personneIndex]?.Prenom
                    )
                ) &&
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-0'}>
                        <div className={'bg-warning p-4 rounded mt-3 f-14 text-center'}>
                            <p className={'mb-0'}>Votre nom et prénom sont identiques.<br/>Merci de vérifier vos informations avant de continuer.</p>
                        </div>
                    </div>
                </div>
            }

            <QuestionLabel className={'f-14 mt-4'} required>
                {questions['paysNaissance'][source]}&nbsp;?&nbsp;
            </QuestionLabel>
            <div className="row">
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                    <Field
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthCountry]`}
                        component={Autocomplete}
                        data={countries && countries.map((c) => ({label: c.name.toUpperCase(), value: c.id }))}
                        required
                        validate={[required]}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                {questions['villeNaissance'][source]}&nbsp;?&nbsp;
            </QuestionLabel>
            <div className="row">
                <div className={'col-12 col-md-6 mt-3 mt-md-2'} data-name="DemandeTarif[ListePersonnes][0][BirthCity]">
                    {
                        /* CAPBIRTH DESACTIVE TEMPORAIREMENT ?

                        birthCountry === 'FRA' ?
                            <Field
                                id={'BirthCity'}
                                icon={'pin'}
                                name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthCity]`}
                                component={AutoCompleteBirth}
                                validate={required}
                                country={birthCountry}
                                onChangeCityFunction={(value, listVilles) => {
                                    changeValue(`DemandeTarif[ListePersonnes][${personneIndex}][BirthDepartment]`, null)
                                    if (value) {
                                        const villeSelected = _.find(listVilles, ['value', value]);
                                        if (villeSelected?.department) {
                                            changeValue(`DemandeTarif[ListePersonnes][${personneIndex}][BirthDepartment]`, villeSelected.department)
                                        }
                                    }
                                }}
                            />
                            :
                        <Field
                            component={Text}
                            name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthCity]`}
                            normalize={(v) => v.toUpperCase()}
                            validate={[required]}
                        />
                    */}

                    <Field
                        component={Text}
                        name={`DemandeTarif[ListePersonnes][${personneIndex}][BirthCity]`}
                        normalize={(v) => v.toUpperCase()}
                        validate={[required, minLentghTwo]}
                    />
                </div>
            </div>
        </>
    );
};

PersonneFields.propTypes = {
    personneIndex: PropTypes.number.isRequired,
};

export default PersonneFields;
