import {useEffect} from 'react';
import {Field} from "redux-form";
import QuestionContentContainer from "../../../QuestionContent/QuestionContentContainer";
import {
    maxDateToday,
    required,
    permisAM,
    permisA1,
    permisA2,
    permisA,
    permisB,
    requiredDateWithError, onError
} from "@amo/core/utils/validateField";
import moment from "moment";
import {validateLicenseByVehicule} from "@amo/core/utils/validateLicense";
import {DateField, Radio, Text} from "@amo/core/components/forms";
import {QuestionLabel} from "@amo/core/components/containers";

const GestionPermis = (props) => {
    const {
        vehicule, validDateNaissance, typePermisAM, typePermisA1, typePermisA2, typePermisA, typePermisB, permis,
        changeValue, unsetValue, dateNaissance, cylindreeVehicule, mineur, datePermisA, permisOnError, datePermisA2,
    } = props
    let validLicense = validateLicenseByVehicule({permis, vehicule, dateNaissance})
    useEffect(() => {
        if (typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) {
            // Si un permis est sélectionné, décocher "Je n'ai pas de permis"
            changeValue('noPermis', null); // ou toute autre valeur qui indique non sélectionné
        }
    }, [typePermisAM, typePermisA1, typePermisA2, typePermisA, typePermisB, changeValue]);
    return (
        <>
            { (moment() > moment(validDateNaissance).add(14, 'years') ) &&
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ListePermis]'}>
                    <Field component={Text} name={'permisOnError'} type={"hidden"} validate={onError} style={{display: 'none'}}/>

                    <QuestionLabel required>Quels permis possède le conducteur&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <div>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'}
                                    type={"checkbox"}
                                    label={'Permis AM (ou&nbsp;BSR)'}
                                    normalize={v => v ? 'AM' : null}
                                    format={v => v === 'AM'}
                                    onChange={(e) => {
                                        unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]')
                                    }}
                                    className={'small f-14'}
                                    dataCy={"permis-AM"}
                                />
                            </div>

                            {
                                typePermisAM &&
                                <div className={'mb-5 blocPermis'}>
                                    <QuestionLabel className={'f-14 mt-0 mb-2'} required>Date d'obtention</QuestionLabel>

                                    <div className={'mt-3 mt-md-2'} data-cy={"date-permis-AM"}>
                                        <Field
                                            component={DateField}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]'}
                                            label={'JJ/MM/AAAA'}
                                            validate={[requiredDateWithError, maxDateToday, permisAM, required]}
                                            maxDate={moment()}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        { (moment() > moment(validDateNaissance).add(16, 'years')) &&
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <div>
                                    <Field
                                        component={Radio}
                                        className={'small'}
                                        name={'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'}
                                        type={"checkbox"}
                                        label={'Permis A1'}
                                        normalize={v => v ? 'A1' : null}
                                        format={v => v === 'A1'}
                                        onChange={(e) => {
                                            unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]')
                                        }}
                                        dataCy={"permis-A1"}
                                    />
                                </div>

                                {
                                    typePermisA1 &&

                                    <div className={'mb-5 blocPermis'}>
                                        <QuestionLabel className={'f-14 mt-0 mb-2'} required>Date d'obtention</QuestionLabel>

                                        <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A1"}>
                                            <Field
                                                component={DateField}
                                                name={'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]'}
                                                label={'JJ/MM/AAAA'}
                                                validate={[requiredDateWithError, maxDateToday, permisA1, required]}
                                                maxDate={moment()}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {!mineur &&
                            <>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <div>
                                        <Field
                                            component={Radio}
                                            className={'small f-14'}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'}
                                            type={"checkbox"}
                                            label={'Permis A2'}
                                            normalize={v => v ? 'A2' : null}
                                            format={v => v === 'A2'}
                                            onChange={(e) => {
                                                unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]')
                                            }}
                                            dataCy={"permis-A2"}
                                        />
                                    </div>

                                    {
                                        typePermisA2 &&

                                        <div className={'mb-5 blocPermis'}>
                                            <QuestionLabel className={'f-14 mt-0 mb-2'} required>Date d'obtention</QuestionLabel>

                                            <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A2"}>
                                                <Field
                                                    component={DateField}
                                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'}
                                                    label={'JJ/MM/AAAA'}
                                                    validate={[requiredDateWithError, maxDateToday, permisA2, required]}
                                                    maxDate={moment()}
                                                />
                                            </div>
                                            {
                                                !datePermisA && moment.isMoment(datePermisA2) && moment(datePermisA2).isValid() && moment(datePermisA2)>= moment('2017-01-01') && moment(datePermisA2) <= moment().subtract(2, 'years') ?
                                                    <div className={'bg-secondary-light p-4 rounded text-left mt-5'}>
                                                        <p className={"text-primary"}><small>A-t-il passé la formation de 7h pour obtenir le permis A ? Si oui, merci de renseigner le permis A.</small></p>
                                                    </div>: ''
                                            }
                                        </div>
                                    }
                                </div>

                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <div>
                                        <Field
                                            component={Radio}
                                            className={'small f-14'}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'}
                                            type={"checkbox"}
                                            label={'Permis A'}
                                            normalize={v => v ? 'A' : null}
                                            format={v => v === 'A'}
                                            onChange={(e) => {
                                                unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]')
                                            }}
                                            dataCy={"permis-A"}
                                        />
                                    </div>
                                    {
                                        typePermisA &&

                                        <div className={'mb-5 blocPermis'}>
                                            <QuestionLabel className={'f-14 mt-0 mb-2'} required>Date d'obtention</QuestionLabel>

                                            <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A"}>
                                                <Field
                                                    component={DateField}
                                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]'}
                                                    label={'JJ/MM/AAAA'}
                                                    validate={[requiredDateWithError, maxDateToday, permisA, required]}
                                                    maxDate={moment()}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        { (moment() > moment(validDateNaissance).add(17, 'years')) &&
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <div>
                                    <Field
                                        component={Radio}
                                        className={'small f-14'}
                                        name={'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'}
                                        type={"checkbox"}
                                        label={'Permis B'}
                                        normalize={v => v ? 'B' : null}
                                        format={v => v === 'B'}
                                        onChange={(e) => {
                                            unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]')
                                            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', null)
                                            changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', null)
                                        }}
                                        dataCy={"permis-B"}
                                    />
                                </div>
                                {
                                    typePermisB &&

                                    <div className={'mb-5 blocPermis'}>
                                        <QuestionLabel className={'f-14 mt-0 mb-2'} required>Date d'obtention</QuestionLabel>

                                        <div className={'mt-3 mt-md-2'} data-cy={"date-permis-B"}>
                                            <Field
                                                component={DateField}
                                                name={'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'}
                                                label={'JJ/MM/AAAA'}
                                                validate={[requiredDateWithError, maxDateToday, permisB, required]}
                                                maxDate={moment()}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        { (cylindreeVehicule <= 50 && moment(validDateNaissance) < moment('1988-01-01')) &&
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <div>
                                    <Field
                                        component={Radio}
                                        className={'small f-14'}
                                        name={'noPermis'}
                                        value={'0'}
                                        type={"checkbox"}
                                        label={'Je n\'ai pas de permis'}
                                        onClick={(e) => {
                                            const newValue = e.target.checked;
                                            if (newValue) {
                                                changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', []);
                                                changeValue('noPermis', '0');
                                            } else {
                                                // Effectuez ici l'action correspondant à la décoche du bouton
                                                changeValue('noPermis', null);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    {
                        permisOnError && permisOnError === 'onError' &&
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>Vous devez renseigner au moins un permis.</p>
                        </div>
                    }
                    {
                        !(permisOnError && permisOnError === 'onError') && !validLicense?.status && <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>{validLicense?.message || 'Les permis sélectionnés ne permettent pas de conduire le véhicule. Si vous n\'avez pas rempli les permis, merci de remplir ceux obtenus par le client en mettant les bonnes dates.'}</p>
                        </div>
                    }
                    {
                        validLicense?.status && validLicense?.message && <div className={'bg-warning p-4 rounded text-left mt-3 f-14'}>
                            <p className="mb-0">{validLicense?.message}</p>
                        </div>
                    }

                </QuestionContentContainer>
            }
        </>
    );
};

GestionPermis.defaultProps = {};
GestionPermis.propTypes = {};

export default GestionPermis;
