import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { QuestionLabel } from '@amo/core/components/containers';

const SelectModele = (props) => {
    const {
        marque_id,
        cylindree,
        setVehiculierAffiche,
        changeValue,
        modeles,
        setVehicule,
        selectedVehiculeIsDisabled,
        canal,
    } = props;
    const [listModels, setListModels] = useState([]);
    const [listModelsFilter, setListModelsFilter] = useState([]);

    useEffect(() => {
        createDefaultArrayModels();
    }, []);

    const filterArrayModeleBySearch = (value) => {
        createDefaultArrayModels();

        let filterBySearch = _.filter(listModels, (m) => {
            return _.includes(m.model, _.upperCase(value));
        });

        setListModelsFilter(filterBySearch);
    };

    const createDefaultArrayModels = () => {
        let listModels = [];
        let date;

        modeles.map((modele) => {
            if (modele.brand.id === marque_id && modele.cylindree === cylindree && modele.cylindree !== 50) {

                if (!_.find(listModels, ['model', `${modele.modele} ${modele.version} (${date})`])) {
                    listModels.push({ model: `${modele.modele} ${modele.version}`, modele });
                }
            } else if (
                modele.brand.id === marque_id &&
                modele.cylindree === cylindree &&
                !_.find(listModels, ['model', modele.modele])
            ) {
                listModels.push({ model: modele.modele, modele });
            }
            return modele;
        });

        setListModels(listModels);
        setListModelsFilter(listModels);
    };

    const selectModele = (value) => {
        const vehicule = _.find(modeles, ['sra', value.modele.sra]);
        setVehicule({ data: vehicule });

        if (vehicule.souscription_disabled) {
            selectedVehiculeIsDisabled({
                canal: canal,
                sra: value.modele.sra,
                type: 'disabled',
            });
        }

        changeValue(`modeleVehicule`, value.model);
        changeValue(`code_categorie`, value.modele.codecategorie);
        changeValue('DemandeTarif[Vehicule][IdentifiantSRA]', value.modele.sra);
        changeValue('typeVehicule', value.modele.type.id);
        changeValue('fuse-find-modele', `${vehicule.brand.name} ${vehicule.cylindree} ${vehicule.modele}`);
        setVehiculierAffiche(false);
    };

    return (
        <div>
            <div className={'row align-items-center'}>
                <QuestionLabel className={'f-14 mt-0 mb-2 col-12  col-md-6'}>Selectionnez le modèle :</QuestionLabel>

                <div className={'col-12 col-md-6'}>
                    <Field
                        component={Text}
                        name={'find-modele'}
                        placeholder={'Recherchez votre modèle'}
                        onChange={(e) => filterArrayModeleBySearch(e.target.value)}
                    />
                </div>

                {_.orderBy(listModelsFilter, ['model'], ['asc']).map((model, index) => {
                    let date = `${
                        model.modele.annee_debut_version !== null && model.modele.annee_debut_version !== 0
                            ? `depuis ${model.modele.annee_debut_version}`
                            : ''
                    } ${
                        model.modele.annee_fin_version !== null && model.modele.annee_fin_version !== 0
                            ? ` jusqu'a ${model.modele.annee_fin_version}`
                            : ''
                    }`;
                    return(
                        <div key={index} onClick={(e) => selectModele(model)} className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <div className={'blocContainer border rounded text-center h-100 cursor-pointer p-3'} data-cy={model.modele.sra}>
                                <span dangerouslySetInnerHTML={{__html: model.model}}/> <small>{date}</small>
                            </div>
                        </div>
                    )
                })}
        </div>
        </div>
    );
};

export default SelectModele;
