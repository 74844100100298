import { BulleAide } from '@amo/core/components/containers';

const BlocInformations = (props) => {
    const { cylindree, codeCat, genre, groupe } = props;
    let textHtml = '';

    if (codeCat && (codeCat == 'END' || codeCat == 'TRI' || codeCat == 'CRO')) {
        textHtml = `
            <p>Pour conduire ce véhicule le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 18 ans</li>
                <li>Son bonus est inférieur ou égal à 1,00</li>
            </ul>`;
    } else if (cylindree && cylindree == 50) {
        textHtml = `<p>Pour conduire un 50 cm³, le client doit etre titulaire au minimum du permis AM (BSR) s'il est né après le 01/01/1988.</p>`;
    } else if (cylindree && cylindree > 50 && cylindree <= 125) {
        textHtml = `
            <p>Pour conduire une moto de 125cm³ avec un permis B le client doit :</p>
            <ul>
                <li>Avoir suivi une formation à la conduite d'une moto 125 cm³</li>
                <li>Ou avoir été assuré entre le 01/01/2006 et le 01/01/2011 pour cette typologie de véhicule</li>
            </ul>`;
    } else if (
        cylindree &&
        cylindree > 50 &&
        (codeCat == 'MAGA' || codeCat == 'QAD' || codeCat == 'QLOM' || codeCat == 'QLEM')
    ) {
        textHtml = `
            <p>Pour conduire ce véhicule le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Etre titulaire du permis B ou Permis A obtenu avant le 19 janvier 2013</li>
                <li>Son bonus est inférieur ou égal à 1,00</li>
            </ul>`;
    } else if (genre && genre == 'A2') {
        textHtml = `
            <p>Pour assurer cette moto chez nous, le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 18 ans</li>
                <li>Il a le permis A ou A2</li>
                <li>Son bonus est inférieur ou égal à 1,56</li>
            </ul>`;
    } else if (genre && genre == 'TRI') {
        textHtml = `
            <p>Pour assurer ce véhicule chez nous, le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 21 ans</li>
                <li>Il a le permis nécessaire à la conduite du véhicule sélectionné (A ou B avec justificatif de formation ou d’une expérience de conduite).</li>
            </ul>`;
    } else if (groupe && groupe >= 4 && groupe <= 14 && genre != 'A2') {
        textHtml = `
            <p>Pour assurer cette moto chez nous, le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 18 ans</li>
                <li>Il a le permis A</li>
                <li>Son bonus est inférieur ou égal à 1,56</li>
            </ul>`;
    } else if (groupe && groupe == 15 && genre != 'A2') {
        textHtml = `
            <p>Pour assurer cette moto chez nous, le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 21 ans</li>
                <li>Il a le permis A ou B depuis au moins 24 mois</li>
                <li>Son bonus est inférieur ou égal à 1,56</li>
                <li>Il peut justifier de 12 mois consécutifs d'assurance auto ou moto sur les 24 derniers mois</li>
            </ul>`;
    } else if ((groupe && groupe == 16 && genre != 'A2') || (groupe && groupe == 17 && genre != 'A2')) {
        textHtml = `
            <p>Pour assurer cette moto chez nous, le client doit remplir les conditions suivantes :</p>
            <ul>
                <li>Il a au moins 21 ans</li>
                <li>Il a le permis A ou B depuis au moins 24 mois</li>
                <li>Son bonus est inférieur ou égal à 0,90</li>
                <li>Il peut justifier de 24 mois consécutifs d'assurance auto ou moto sur les 24 derniers mois</li>
            </ul>`;
    } else if (groupe && groupe == 18 && genre != 'A2') {
        textHtml = `
            <p>Pour assurer cette moto chez nous, le client doit remplir les conditions suivantes :</p>
            <p className="font-weight-bold">si vous avez au moins 25 ans</p>
            <ul>
                <li>Il a le permis A depuis au moins 60 mois (5 ans)</li>
                <li>Son bonus est inférieur ou égal à 0,80 (en moto)</li>
                <li>Il peut justifier de 18 mois consécutifs d'assurance moto sur les 24 derniers mois</li>
            </ul>
            <p className="font-weight-bold">si vous avez au moins 30 ans</p>
            <ul>
                <li>Il a le permis A depuis au moins 24 mois (2 ans)</li>
                <li>Son bonus est inférieur ou égal à 0,90 (en moto)</li>
                <li>Il peut justifier de 18 mois consécutifs d'assurance moto sur les 24 derniers mois</li>
            </ul>`;
    }

    if (cylindree && textHtml) {
        return <BulleAide text={textHtml} className={'col-lg-12 col-md-12 f-14'} />;
    }

    return null;
};

export default BlocInformations;
