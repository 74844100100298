import {useEffect, useState} from 'react';
import {Field} from "redux-form";
import QuestionContentContainer from "../../QuestionContent/QuestionContentContainer";
import {
    maxDateToday,
    required,
    min14Year,
    min18Year, requiredDateWithError, min16Year, validatePhone, validateEmail, onError
} from "@amo/core/utils/validateField";
import moment from "moment";
import TitulaireCarteGriseMajeur from "./TitulaireCarteGriseMajeur/TitulaireCarteGriseMajeur";
import _ from "lodash";
import { crm } from "@amo/core/utils/functions";
import { getCountries } from '@amo/core/utils/requestsApi';
import {validateLicenseByVehicule} from "@amo/core/utils/validateLicense";
import TitulaireCarteGriseMineur from "./TitulaireCarteGriseMineur/TitulaireCarteGriseMineur";
import GestionPermis from "./GestionPermis/GestionPermis";
import questions from "../../../../../../utils/json/questions.json"
import {BulleAide, QuestionLabel, SimpleTitle} from "@amo/core/components/containers";
import {DateField, Radio, Select, Checkbox, Text} from "@amo/core/components/forms";
import {normalizeTel} from "@amo/core/utils/index.js";
import warning from "@amo/core/assets/images/AMO_Icons/Warning.svg";

const Profil = (props) => {
    const {
        acceptRecontact, apporteur, source, vehicule, conducteur, typePermisAM, typePermisA1, typePermisA2, typePermisA, typePermisB, permis,
        changeValue, unsetValue, dateNaissance, valeurCrmMoto, valeurCrmAuto,
        cylindreeVehicule, familleProduit, datePermisB,
        minCRMAuto, minCRMMoto, titulaireCarteGrise, permisOnError, vehiculeImmatricule,
        conducteurMineur, valid, handleSubmit, nextStep, fillLoading, previousStep,
        setCountries, dejaClientRefresh, dejaClientData, listePersonnes,
        formation, DeclarationAssurance, dateNaissanceRepresentant
    } = props
    const [mineur, setMineur] = useState(moment.isMoment(dateNaissance) && moment(dateNaissance).isValid() && moment(dateNaissance).add(18, 'years') > moment());
    const [validDateNaissance, setValidDateNaissance] = useState(moment.isMoment(dateNaissance) && moment(dateNaissance).isValid()  ? moment(dateNaissance) : null);
    let validLicense = validateLicenseByVehicule({permis, vehicule, dateNaissance})
    const [dateNaissanceValue, setDateNaissanceValue] = useState(dateNaissance);
    const [titulaireCarteGriseValue, setTitulaireCarteGriseValue] = useState(titulaireCarteGrise);
    const isDejaClient = !_.isEmpty(dejaClientData);

    const shouldHandleClick = () => {
        const codes = [479846, 111619, 479835];
        if (codes.includes(apporteur.codeBelair)) {
            return acceptRecontact;
        }
        return true;
    }

    const isButtonDisabled = () => {
        const codes = [479846, 111619, 479835];
        const isCodeInList = codes.includes(apporteur.codeBelair);
        const shouldDisable = isCodeInList ? !acceptRecontact : false;

        return (!valid || validLicense?.status === false) || shouldDisable || isNonEligible || DeclarationAssurance === '0';
    }

    const changeAge = nouvelleDateNaissance => {
        if( moment.isMoment(nouvelleDateNaissance) && moment(nouvelleDateNaissance).isValid() ) {
            if ( moment(nouvelleDateNaissance).add(18, 'years') > moment()&& !mineur) {
                changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P');
                changeValue('DemandeTarif[ListePersonnes][1][RoleConducteur]', 'N');
                changeValue('titulaireCarteGrise', null);
                changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
            } else if(mineur) {
                //Si ListePersonnes[0] indiqué mineur mais maintenant majeur
                changeValue('DemandeTarif[ListePersonnes]', [{...conducteur}])
                changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P');
                changeValue('DemandeTarif[ListePersonnes][1][DateNaissance]', dateNaissanceRepresentant);
            }

            setValidDateNaissance(nouvelleDateNaissance);
            setMineur(moment(nouvelleDateNaissance).add(18, 'years') > moment());

            //Si on a aucun permis de renseigné et que l'on a pas le choix "je n'ai pas de permis" on affiche une erreur
            changeValue('permisOnError', !(cylindreeVehicule <= 50 && moment(nouvelleDateNaissance) < moment('1988-01-01')) && !(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) ? 'onError' : null);
        }
    }

    useEffect(() => {
        if (moment(dateNaissance, 'DD/MM/YYYY', true).isValid() && dateNaissance !== dateNaissanceValue) {
            dejaClientRefresh({updateOnlyPersonFields: true});
            setDateNaissanceValue(dateNaissance);
            //Vérifier si la date de naissance est valide
        }
    }, [dateNaissance]);

    useEffect(() => {
        if(titulaireCarteGriseValue !== titulaireCarteGrise) {
            dejaClientRefresh({updateOnlyPersonFields: true});
            setTitulaireCarteGriseValue(titulaireCarteGrise);
        }
    }, [titulaireCarteGrise]);

    useEffect(() => {
        (async function fetchCountries(){
            let countries = await getCountries()
            setCountries({countries})
        })();
    }, [])

    useEffect(() => {
        //Si on a aucun permis de renseigné et que l'on a pas le choix "je n'ai pas de permis" on affiche une erreur
        changeValue('permisOnError', !(cylindreeVehicule <= 50 && moment(validDateNaissance) < moment('1988-01-01')) && !(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) ? 'onError' : null);

        //Si on a aucun permis de renseigné et que l'on a pas le choix "je n'ai pas de permis" on affiche une erreur
        if(!_.find(permis, function(p) { return (p && p.TypePermis); })){
            changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
        }

        if(!(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB)) {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', null);
        }

    }, [permis])

    useEffect(() => {
        if(familleProduit !== '50'){
            changeValue('DemandeTarif[ListePersonnes][0][ConducteurMineur]', false);
        }

    }, [familleProduit])
    const hasOnlyPermisB = (permis) => {
        return !(_.find(permis, ['TypePermis', 'A1']) || _.find(permis, ['TypePermis', 'A']) || _.find(permis, ['TypePermis', 'A2'])) && _.find(permis, ['TypePermis', 'B']);
    };

    const isNonEligible = formation === '0' && datePermisB.isSameOrAfter(moment('2011-01-01'));

    return (
        <div>
            <SimpleTitle>{questions['titreProfil'][source]}</SimpleTitle>

            {
                familleProduit === '50' &&
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ConducteurMineur]'}>
                    <div className={'mt-4 mt-md-4'}>
                        <Field
                            name="DemandeTarif[ListePersonnes][0][ConducteurMineur]"
                            component={Checkbox}
                            type={"checkbox"}
                        >
                            <div className={'f-14 mx-2'}>Le futur conducteur ne dispose pas encore du permis AM ou n'a pas 14 ans.</div>
                        </Field>
                    </div>
                </QuestionContentContainer>
            }
            {
                conducteurMineur && <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                    <p className={'mb-0'}>
                        Le contrat sera fait au nom du responsable légal et celui-ci sera déclaré conducteur habituel jusqu'à modification du contrat.<br/><br/>
                        <i>Le futur conducteur sera un enfant de plus de 14 ans qui disposera du permis AM et le client s'engage à le déclarer dès qu'il en disposera.</i>
                    </p>
                </div>
            }

            <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}>
                <QuestionLabel required>{questions['dateNaissance'][source]}&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                            label={'JJ/MM/AAAA'}
                            validate={[
                                requiredDateWithError, maxDateToday,
                                familleProduit === 'verte' ? min18Year : (cylindreeVehicule <= 50 ? min14Year : cylindreeVehicule <= 125 ? min16Year : min18Year)
                            ]}
                            maxDate={moment()}
                            onChange={changeAge}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {
                mineur ?
                    <>
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][1][DateNaissance]'}>
                            <QuestionLabel required>Quelle est la date de naissance du représentant légal&nbsp;?&nbsp;</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                                    <Field
                                        component={DateField}
                                        name={'DemandeTarif[ListePersonnes][1][DateNaissance]'}
                                        label={'JJ/MM/AAAA'}
                                        validate={[requiredDateWithError, maxDateToday, min18Year]}
                                        maxDate={moment()}
                                        onChange={() => changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P')}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    </> : ''
            }



            {
                vehiculeImmatricule !== '0' &&
                <>
                    <QuestionContentContainer name={'titulaireCarteGrise'}>
                        <QuestionLabel required>La carte grise est au nom :&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            {!mineur
                                ? <TitulaireCarteGriseMajeur
                                    conducteur={conducteur}
                                    familleProduit={familleProduit}
                                    changeValue={changeValue}
                                    isDejaClient={isDejaClient}
                                    isSociety={listePersonnes?.[1]?.TypePersonne === 'M'}/>
                                : <TitulaireCarteGriseMineur conducteur={conducteur} changeValue={changeValue}/>
                            }
                        </div>
                        {
                            titulaireCarteGrise === 'onError' &&
                            <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>Le souscripteur doit être le titulaire de la carte grise.</p>
                            </div>
                        }
                    </QuestionContentContainer>
                    <BulleAide
                        icon={warning}
                        title={`<b>Cas du véhicule en leasing</b>`}
                        text={questions['leasing'][source]}
                        className={'col-lg-12 col-md-12 f-14 mt-4'}
                    />
                </>
            }

            <GestionPermis {...props} validDateNaissance={validDateNaissance} mineur={mineur} permisOnError={permisOnError}/>

            {!mineur && validDateNaissance && !datePermisB && cylindreeVehicule >125 && _.includes(['moto', 'scooter'], familleProduit) &&
                <BulleAide
                    icon={warning}
                    title={`<b>ATTENTION</b>`}
                    text={`<p>Le permis B n'est pas renseigné, sa date d'obtention est prise en compte dans le calcul de la prime.</p>
                        <p>Merci de le préciser pour bénéficier de notre meilleur tarif.</p>`}
                    className={'col-lg-12 col-md-12 f-14'}
                />
            }
            {(((familleProduit?.includes('moto') || familleProduit?.includes('scooter') || familleProduit?.includes('verte')) && Number(cylindreeVehicule) > 50 && Number(cylindreeVehicule) <= 125 && hasOnlyPermisB(permis)) || (vehicule?.genre === 'TRI' && !_.find(permis, (p) => p?.TypePermis === 'A'))) && (
                <>
                    {(datePermisB >= moment('2011-01-01') || (datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01'))) && (
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][Formation]'}>
                            <QuestionLabel required>
                                Vous déclarez avoir réalisé la formation de 7h pour conduire ce type de véhicule&nbsp;?
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][Formation]'}
                                        value={'1'}
                                        label={'Oui'}
                                        className={'small'}
                                        validate={required}
                                        onChange={() => changeValue('DemandeTarif[ListePersonnes][0][DeclarationAssurance]', null)}
                                    />
                                </div>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][Formation]'}
                                        value={'0'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                            {isNonEligible && (
                                <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                                    <b>Vous pouvez conduire une moto 125cm³ avec un permis B si vous remplissez les 2 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous pouvez conduire un scooter 3 roues de la catégorie L5e avec un permis B si vous remplissez
                                        les 3 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez 21 ans ou plus<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous êtes dispensé de suivre la formation dans les 3 cas suivants :</b><br />
                                    - Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br />
                                    - Vous avez conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010<br />
                                    - Si vous avez suivi la formation de 3 heures avant 2011
                                </div>
                            )}
                        </QuestionContentContainer>
                    )}
                    {(datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01') && formation === '0') && (
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}>
                            <QuestionLabel required>
                                Vous déclarez avoir été assuré de manière permanente ou non pour un 2 roues 125cm³ ou un 3 roues de la catégorie L5e entre 2006 et 2010&nbsp;?
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                                        value={'1'}
                                        label={'Oui'}
                                        className={'small'}
                                        validate={required}
                                    />
                                </div>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                                        value={'0'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                            {DeclarationAssurance === '0' && (
                                <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                                    <b>Vous pouvez conduire une moto 125cm³ avec un permis B si vous remplissez les 2 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous pouvez conduire un scooter 3 roues de la catégorie L5e avec un permis B si vous remplissez
                                        les 3 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez 21 ans ou plus<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous êtes dispensé de suivre la formation dans les 3 cas suivants :</b><br />
                                    - Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br />
                                    - Vous avez conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010<br />
                                    - Si vous avez suivi la formation de 3 heures avant 2011
                                </div>
                            )}
                        </QuestionContentContainer>
                    )}
                </>
            )}

            {
                (!_.includes(['quad', 'verte', '50'], familleProduit)) && (typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) ?
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}>
                        <QuestionLabel required>{questions['bonus2roues'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8 col-md-8 mt-4 mt-md-4'}>
                                <Field
                                    component={Select}
                                    name={`DemandeTarif[ListePersonnes][0][ValeurCrmMoto]`}
                                    onChange={(e) => {
                                        changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', null)
                                        changeValue('firstValeurCrmMotoPlus3Ans', null)
                                        changeValue('firstValeurCrmMoto', e.target.value);
                                    }}
                                    validate={required}
                                >
                                    <option value="">Quel est votre bonus-malus 2-roues ?</option>
                                    {crm.map((c, index) => (
                                        <option key={index} value={c.code}>{c.libelle}</option>
                                    ))}
                                </Field>
                            </div>
                        </div>

                        {((valeurCrmMoto < minCRMMoto) && valeurCrmMoto !== null) && (
                            <p className={'m-0 text-error text-danger f-13'}>
                                Attention, le bonus saisi est inférieur au bonus calculé automatiquement par rapport à
                                vos dates de permis (valeur {minCRMMoto}). Merci de bien vérifier cette information
                                avant de valider cette étape.
                            </p>
                        )}
                    </QuestionContentContainer> : ''
            }

            {
                Number(valeurCrmMoto) === 0.50 && <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}>
                    <QuestionLabel required>{questions['bonus2roue3ans'][source]}&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                                value={'1'}
                                type={"radio"}
                                label={'Oui'}
                                validate={required}
                                className={'small'}
                                onChange={e => changeValue('firstValeurCrmMotoPlus3Ans', e.target.value)}
                            />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                                value={'0'}
                                type={"radio"}
                                label={'Non'}
                                className={'small'}
                                validate={required}
                                onChange={e => changeValue('firstValeurCrmMotoPlus3Ans', e.target.value)}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            }

            {
                typePermisB && !_.includes(['quad', 'verte', '50'], familleProduit) && <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'}>
                        <QuestionLabel required>{questions['bonusAuto'][source]}&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8 col-md-8 mt-4 mt-md-4'}>
                                <Field
                                    component={Select}
                                    name={`DemandeTarif[ListePersonnes][0][ValeurCrmAuto]`}
                                    onChange={(e) => {
                                        changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', null)
                                        changeValue('firstValeurCrmMotoPlus3Ans', null)
                                        changeValue('firstValeurCrmAuto', e.target.value);
                                    }}
                                    validate={required}
                                >
                                    <option value="">Quel est votre bonus-malus auto ?</option>
                                    {crm.map((c, index) => (
                                        <option key={index} value={c.code}>{c.libelle}</option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        {((valeurCrmAuto < minCRMAuto) && valeurCrmAuto !== null) && (
                            <p className={'m-0 text-error text-danger f-13'}>
                                Attention, le bonus saisi est inférieur au bonus calculé automatiquement par rapport
                                à vos dates de permis (valeur {minCRMAuto}). Merci de bien vérifier cette
                                information avant de valider cette étape.
                            </p>
                        )}
                    </QuestionContentContainer>
                    {
                        Number(valeurCrmAuto) === 0.50 && <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}>
                            <QuestionLabel required>{questions['bonusAuto3ans'][source]}&nbsp;?&nbsp;</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}
                                        value={'1'}
                                        type={"radio"}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                        onChange={e => changeValue('firstValeurCrmAutoPlus3Ans', e.target.value)}
                                    />
                                </div>

                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}
                                        value={'0'}
                                        type={"radio"}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                        onChange={e => changeValue('firstValeurCrmAutoPlus3Ans', e.target.value)}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    }
                </>
            }

            {
                _.includes([479846, 111619, 479835], apporteur.codeBelair) && <>
                    <SimpleTitle className={'mt-5'}>{questions['informationsDeContactClient'][source]}</SimpleTitle>
                    <QuestionContentContainer name={"DemandeTarif[ListePersonnes][0][TelPortable]"}>
                        <QuestionLabel required>
                            {questions['numeroTel'][source]}&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                                    icon={'phone'}
                                    iconSize={18}
                                    required
                                    validate={[required, validatePhone]}
                                    normalize={normalizeTel}
                                    onChange={(e) => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                                />
                            </div>
                        </div>
                        <p className={'m-0 text-grey'}>
                            <small>{questions['usageTel'][source]}</small>
                        </p>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={"DemandeTarif[ListePersonnes][0][Email]"}>
                        <QuestionLabel required>
                            {questions['emailClient'][source]}&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][Email]'}
                                    icon={'envelope'}
                                    validate={[required, validateEmail]}
                                    iconSize={18}
                                    onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                                />
                            </div>
                        </div>
                        <p className={'m-0 text-grey'}>
                            <small>{questions['textEmailClient'][source]}</small>
                        </p>
                    </QuestionContentContainer>

                    <div className="mt-3">
                        <QuestionContentContainer name={"accept-recontact"}>
                            <Field name="accept-recontact" component={Checkbox}>
                                <p className={'ml-3'}>
                                    Afin d'être accompagné dans le cadre de ma démarche d'assurance, j'accepte d'être contacté par téléphone et/ou email
                                </p>
                                <small className={'ml-3 d-block'}>
                                    Pour vous conseiller au mieux dans le cadre de votre démarche, vous pourrez être rappelé par un conseiller APRIL&nbsp;d'ADM&nbsp;Value (N°ORIAS&nbsp;:&nbsp;10055724)
                                </small>
                                <p className={'ml-3'}>
                                    <sup className={'text-success'}>Obligatoire</sup>
                                </p>
                            </Field>
                            <Field name="offre" component={Checkbox}>
                                <p className={'ml-3'}>
                                    Je souhaite être informé des dernières offres des partenaires d’APRIL Moto
                                </p>
                            </Field>
                        </QuestionContentContainer>
                    </div>

                    <BulleAide
                        icon={warning}
                        title={`<b>Données personnelles</b>`}
                        text={`<p>Les données personnelles que vous renseignez dans ce formulaire sont nécessaires au calcul de votre devis, pour la conclusion et l'exécution du contrat d’assurance deux roues et la prise en charge des garanties qui y sont associées.</p>`}
                        className={'col-lg-12 col-md-12 f-14 mt-3'}
                    />
                </>
            }

            <div className={'submit-row mt-5'}>
                <button
                    className={`${isButtonDisabled() ? 'disabled' : ''} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    onClick={valid && !isNonEligible &&
                    DeclarationAssurance !== 0 && !isButtonDisabled() && (validLicense === undefined || validLicense.status) && shouldHandleClick() ? handleSubmit(nextStep) : null}
                    data-id="btn-next">
                    {fillLoading
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                        : 'Continuer'
                    }
                </button>


                <button className={`btn btn-outline-primary medium-rounded order-lg-0`}
                        onClick={() => previousStep()}>Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}><small>Merci de vérifier si tous les champs du formulaire ont bien été
                    saisis.</small></p>
            </div>

        </div>
    );
};

Profil.defaultProps = {};

Profil.propTypes = {};

export default Profil;
