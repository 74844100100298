import { APP_NAME } from '@amo/core/constants/constants.js';
import { setNotification } from 'formulaire-direct/src/redux/actions/core/notifications/notifications.actions.js';
import _ from 'lodash';
import moment from 'moment';
import { getSlugStep } from '../../../../utils/function';
import { INIT, POST_INIT, setInit } from '../../../actions/app/init/init.actions';
import { setError, setIndexQuestion, setLoader, setMaxStep, setStep, setUtmSource } from '../../../actions/app/ui/ui.actions';
import { getVehicule, VEHICULE } from '../../../actions/app/vehicule/vehicule.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { clearLocalStorage, setLocalStorage } from '../../../actions/core/localStorage/localStorage.actions';

export const initMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const { payload } = action;
        let nextActions = [];
        let locationSearch = window.location.search.substring(1);

        switch (action.type) {
            case POST_INIT:
                /* Verify expire token 1h */
                let body = payload.body;

                if ((localStorage.getItem(APP_NAME) && moment(JSON.parse(localStorage.getItem(APP_NAME)).expire).add(1, 'hours') < moment()) || body.key) {
                    body.token = null;
                }

                nextActions = [apiRequest({ body, method: 'POST', url: payload.data, entity: INIT }), setLoader({ state: true, entity: INIT })];

                if (locationSearch.utm_source) {
                    nextActions.push(setUtmSource({ data: locationSearch }));
                }

                next(nextActions);
                break;

            case `${INIT} ${API_SUCCESS}`:
                const {
                    state,
                    token,
                    data: { source, DemandeTarif },
                    whitelabel,
                    apporteur,
                } = action.payload.data;
                const local = JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`));

                nextActions = [clearLocalStorage({ entity: INIT }), setLocalStorage({ entity: INIT, storage: { token } })];

                if (apporteur?.stadeGIE === 'CL') {
                    nextActions.push(
                        setNotification({
                            entity: INIT,
                            html: 'Votre code apporteur ne permet pas d’ouvrir le formulaire de souscription.',
                            title: 'Votre code apporteur est invalide',
                            icon: 'error',
                            confirmButtonText: "J'ai compris",
                            allowOutsideClick: false,
                            successFunction: () => {
                                window.location.reload();
                            },
                        }),
                    );
                }

                /* Ajout des options souscrites au retour du paiement */
                if (locationSearch.paid === '1' && action.payload.data.data.Devis) {
                    const optionsSouscrites = _.filter(action.payload.data.data.Devis.Formule.ListeGaranties, (g) => {
                        return g.TypeGarantie === 'Option' && g.Souscrite === true;
                    });
                }

                if (payload.data.data.DemandeTarif && payload.data.data.DemandeTarif.Vehicule && payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA) {
                    nextActions.push(setLoader({ state: true, entity: VEHICULE }));
                }

                /* Verifie la source pour conserver le canal d'origine et si courtier on le met a jour */
                if ((local && local.source) !== source || (source === 'courtier' && action.payload.meta.otherData)) {
                    window.localStorage.setItem(`${APP_NAME}-source`, JSON.stringify({ source, key: action.payload.meta.otherData }));
                }

                /* si la source n'est pas le direct on remplace les variables par le WhiteLabel */
                const couleurSite = source === 'site' && payload.data.apporteur.codeBelair === 43397;

                if (!couleurSite) {
                    for (let [key, value] of Object.entries(whitelabel)) {
                        if (_.startsWith(key, 'style_color')) {
                            document.documentElement.style.setProperty(`--${key}`, value);
                        }
                    }
                }

                switch (state) {
                    /* Nouveau devis */
                    case 'new':
                        nextActions = nextActions.concat([
                            setStep({ step: getSlugStep('votre-vehicule') }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);

                        if (payload.data.data.DemandeTarif.Vehicule && payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA) {
                            nextActions.push(setIndexQuestion({ index: 2 }));
                        }
                        break;

                    /* Etape de tarif avant paiement */
                    case 'tarif':
                    case 'devis_orange':
                        nextActions = nextActions.concat([
                            // On redirige sur la 1ere étape pour les comparateurs et les données manquantes
                            // setStep({step: getSlugStep('votre-tarif')}),
                            // setMaxStep({maxStep: getSlugStep('votre-tarif')}),
                            setStep({ step: getSlugStep('votre-vehicule') }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    case 'devis':
                        const commonActions = [setInit({ init: payload.data }), setError({ state: false, entity: INIT }), setLoader({ state: false, entity: INIT })];

                        if (DemandeTarif.Subscribe) {
                            nextActions = nextActions.concat([setStep({ step: getSlugStep('recapitulatif') }), ...commonActions]);
                        } else {
                            nextActions = nextActions.concat([setStep({ step: getSlugStep('votre-vehicule') }), ...commonActions]);
                        }
                        break;

                    /* save_devis fait, en attente du paiement */
                    case 'contrat_en_attente_de_paiement':
                        nextActions = nextActions.concat([
                            setStep({ step: getSlugStep('paiement') }),
                            setMaxStep({ maxStep: getSlugStep('paiement') }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    /* retour du paiement en attente de la validation pour signature */
                    case 'contrat_en_cours_de_validation':
                        nextActions = nextActions.concat([
                            setStep({ step: getSlugStep('validation') }),
                            setMaxStep({ maxStep: getSlugStep('validation') }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    /* save_contrat fait */
                    case 'contrat':
                        /* Vérifie qu'une signature est en cours */
                        const step =
                            payload.data.signatures.length > 0 &&
                            payload.data.signatures[0].refused === false &&
                            payload.data.signatures[0].signed === false &&
                            payload.data.signatures[0].started === false
                                ? getSlugStep('signature')
                                : getSlugStep('envoi-des-pieces');

                        nextActions = nextActions.concat([
                            setStep({ step: step }),
                            setMaxStep({ maxStep: step }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    default:
                        return null;
                }
                next(nextActions);

                if (payload.data.data.DemandeTarif && payload.data.data.DemandeTarif.Vehicule && payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA) {
                    dispatch(getVehicule({ idSra: payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA }));
                }
                break;

            case `${INIT} ${API_ERROR}`:
                const reason = payload.data.response.data?.message ?? payload.data.response.data.error;

                nextActions = [
                    setNotification({
                        entity: INIT,
                        icon: 'error',
                        title: 'Une erreur est survenue !',
                        html: `<p>${reason}</p>`,
                        allowOutsideClick: false,
                    }),
                    /*clearLocalStorage({entity: INIT}),*/
                    setError({ state: true, entity: INIT }),
                    setLoader({ state: true, entity: INIT }),
                ];

                next(nextActions);

                break;

            default:
                return null;
        }
    };
