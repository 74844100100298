import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_DEJA_CLIENT, REFRESH_DEJA_CLIENT, setDejaClient} from "../../../actions/app/dejaClient/dejaClient.actions";
import {change} from "redux-form";
import {dejaClient} from "@amo/core/utils/dejaClient.js";

export const dejaClientMiddleware = () => next => action => {
    next(action)

    const { payload } = action;
    let nextAction = [],
        dejaClientListePersonnes,
        listePersonnes,
        typeProduit,
        shouldUpdate

    switch (action.type){
        case POST_DEJA_CLIENT:
            const requestBody = {
                'DemandeTarif': {
                    'ListePersonnes': [
                        {
                            'DejaClientSearch': payload.body.idDejaClient,
                            'TypePersonne': 'P',
                            'RoleConducteur': 'P'
                        }
                    ]
                }
            };

            next([
                apiRequest({ body: requestBody, method: 'POST', url: payload.data, entity: POST_DEJA_CLIENT, otherData: payload.meta.otherData }),
                setLoader({ state: true, entity: POST_DEJA_CLIENT })
            ]);
            break

        case `${POST_DEJA_CLIENT} ${API_SUCCESS}`:
            dejaClientListePersonnes = payload?.data?.data?.DemandeTarif?.ListePersonnes
            typeProduit = payload.meta.otherData.typeProduit
            listePersonnes = payload.meta.otherData.listePersonnes
            shouldUpdate = payload.meta.otherData?.shouldUpdate !== false

            nextAction = [
                setDejaClient({ data: dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient ? ({shouldUpdate, ...payload.data.data}) : {} }),
                setError({state: false, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ];

            if(shouldUpdate) {
                if (dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient) {
                    nextAction = dejaClient('devis', false, typeProduit, payload.data.data, nextAction, listePersonnes);
                    nextAction.unshift(setNotification({
                        entity: POST_DEJA_CLIENT,
                        html: null,
                        type: 'success',
                        title: 'Vous êtes bien identifié !'
                    }));
                } else {
                    nextAction.unshift(change('devis', `DemandeTarif.ListePersonnes.0.NumeroClient`, ''))
                    nextAction.unshift(setNotification({
                        entity: POST_DEJA_CLIENT,
                        html: `<p>Nous n'avons pas pu vous identifier ou votre contrat n'est pas éligible au rechargement des données.</p><p>Nous allons vous demander de remplir l'ensemble des informations nécessaires à la réalisation d'un contrat d'assurance.</p>`,
                        type: 'error',
                        title:  'Oups…<br/>Données indisponibles',
                        timeout: '10000'
                    }))
                }
            }
            next(nextAction)
            break

        case `${POST_DEJA_CLIENT} ${API_ERROR}`:
            next([
                setDejaClient({data: {}}),
                setNotification({
                    entity: POST_DEJA_CLIENT,
                    html: `<p>Nous n'avons pas pu vous identifier ou votre contrat n'est pas éligible au rechargement des données.</p><p>Nous allons vous demander de remplir l'ensemble des informations nécessaires à la réalisation d'un contrat d'assurance.</p>`,
                    type: 'error',
                    title:  'Oups…<br/>Données indisponibles',
                    timeout: '10000'
                }),
                setError({state: true, entity: POST_DEJA_CLIENT}),
                setLoader({state: false, entity: POST_DEJA_CLIENT})
            ])
            break

        case REFRESH_DEJA_CLIENT:
            dejaClientListePersonnes = payload.dejaClientData?.DemandeTarif?.ListePersonnes

            if(dejaClientListePersonnes && dejaClientListePersonnes[0].DejaClient) {
                nextAction = dejaClient('devis', payload.data?.updateOnlyPersonFields, payload.data.typeProduit, payload.dejaClientData, nextAction, payload.data.listePersonnes);
            }

            next(nextAction)
            break

        default:
            return null
    }
}