import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, getFormValues } from 'redux-form';
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js'
import { postFill } from '../../../../../../redux/actions/app/fill/fill.actions';
import { TARIF } from '../../../../../../redux/actions/app/tarif/tarif.actions';
import {
    getApporteur1Init,
    getFraisRecurrentsApporteur,
    getHonorairesApporteur, getIsConcess,
    getSourceDemandeTarifInit,
    getTauxApporteur,
    getToken,
} from '../../../../../../redux/selectors/init/init.selectors';
import {
    getCodeFormulePreconiseeTarif,
    getFormuleFranchises,
    getFormuleGarantiesACCESSEQP,
    getFormuleGarantiesASS,
    getFormuleGarantiesKSM,
    getFormuleGarantiesPCC,
    getFormuleGarantiesSelected,
    getFormuleGarantiesVALMAJ,
    getFraisRecurrentsMaxiApporteurTarif, getFraisRecurrentsTarifApporteurTarif,
    getGarantiesInfoFranchiseDTA,
    getGarantiesInfoFranchiseVI,
    getHonorairesMaxiApporteurTarif, getHonorairesTarifApporteurTarif,
    getInfoCodePromo,
    getListeFormulesTarif,
    getTauxApporteurMaxiApporteurTarif, getTauxApporteurTarifApporteurTarif,
} from '../../../../../../redux/selectors/tarif/tarif.selectors';
import {
    getErrorEntity,
    getLoadingEntity,
    getMaxStep,
    getStep,
} from '../../../../../../redux/selectors/ui/ui.selectors';
import {getTypeVehicule, getVehicule} from '../../../../../../redux/selectors/vehicule/vehicule.selectors';
import {loader} from '@amo/core/components/loaders';
import ErreurTarifContainer from './ErreurTarif/ErreurTarifContainer';
import { reformatFormules } from './reformatFormules';
import { reformatGaranties } from './reformatGaranties';
import Tarif from './Tarif';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    const formuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]');
    const fractionnement = selector(state, 'DemandeTarif[Police][FractionnementChoisi]');

    const codesPromo = ['BC10', 'BC20', 'BC30', 'BC40', 'BC50', 'BC60', 'BC70', 'BC80', 'BC90', 'BC100']
    const needCommentaire = getSourceDemandeTarifInit(state) === "conseiller"
        && codesPromo.includes(selector(state, 'DemandeTarif[CodePromo][CodePromo]'))
        && getInfoCodePromo(state)?.Eligible

    return {
        data: getFormValues('devis')(state),
        step: getStep(state),
        isConcess: getIsConcess(state),
        maxStep: getMaxStep(state),
        values: getFormValues('devis')(state),
        queryFill: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        radioGroupSelectedASS: selector(state, 'options[ASS]'),
        radioGroupSelectedPCC: selector(state, 'options[PCC]'),
        radioGroupSelectedACCESSEQP: selector(state, 'optionsListe[ACCESSEQP]'),
        radioGroupSelectedKSM: selector(state, 'options[KSM]'),
        radioGroupSelectedVALMAJ: selector(state, 'options[VALMAJ]'),
        listeFormules: reformatFormules(getListeFormulesTarif(state), getTypeVehicule(state)),
        formulePreconisee: getCodeFormulePreconiseeTarif(state),
        formuleSelectionnee: formuleChoisie,
        fractionnement: fractionnement,
        query: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        loaded: getLoadingEntity(state, TARIF) === false,
        loading: getLoadingEntity(state, TARIF),
        infoDTA: getGarantiesInfoFranchiseDTA(state, formuleChoisie),
        infoVI: getGarantiesInfoFranchiseVI(state, formuleChoisie),
        garantiesSelected: getFormuleGarantiesSelected(state, formuleChoisie),
        garantiesASS: reformatGaranties(
            getFormuleGarantiesASS(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        garantiesPCC: reformatGaranties(
            getFormuleGarantiesPCC(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        garantiesKSM: reformatGaranties(
            getFormuleGarantiesKSM(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        garantiesVALMAJ: reformatGaranties(
            getFormuleGarantiesVALMAJ(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        garantiesACCESSEQP: reformatGaranties(
            getFormuleGarantiesACCESSEQP(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        garantiesVI: reformatGaranties(
            getFormuleGarantiesACCESSEQP(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        franchises: reformatGaranties(
            getFormuleFranchises(state, formuleChoisie),
            fractionnement,
            getTypeVehicule(state),
        ),
        codePromo: getInfoCodePromo(state),
        codePromoInForm: selector(state, 'DemandeTarif[CodePromo][CodePromo]'),
        error: getErrorEntity(state, TARIF),
        nbMoisAssuranceMoto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'),
        nbMoisAssuranceAuto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'),
        valeurCrmMoto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'),
        valeurCrmAuto: selector(state, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'),
        selectedFranchiseVI: selector(state, 'optionsFranchise[RAFRAVI]'),
        selectedFranchiseDTA: selector(state, 'optionsFranchise[RAFRADTA]'),
        honorairesTarif: getHonorairesTarifApporteurTarif(state),
        honorairesMax: getHonorairesMaxiApporteurTarif(state),
        tauxApporteurTarif: getTauxApporteurTarifApporteurTarif(state),
        tauxApporteurMax: getTauxApporteurMaxiApporteurTarif(state),
        fraisRecurrentsTarif: getFraisRecurrentsTarifApporteurTarif(state),
        fraisRecurrentsMax: getFraisRecurrentsMaxiApporteurTarif(state),
        honoraires: parseInt(getHonorairesApporteur(state)),
        tauxApporteur1: parseInt(getTauxApporteur(state)),
        fraisReccurent: parseInt(getFraisRecurrentsApporteur(state)),
        apporteur1: getApporteur1Init(state),
        needCommentaire,
        vehicule: getVehicule(state),
    };
};

const mapDispatchToProps = {
    postFill,
    changeValue: (field, value) => change('devis', field, value),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryFill, step, maxStep, values } = stateProps;
    const { postFill } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        reloadTarif: () => postFill({ step, maxStep, form: values, query: queryFill, urlReloadTarif: query }),
    };
};

const TarifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ ErrorIndicator: ErreurTarifContainer, message: 'Nous préparons nos formules !' }),
)(Tarif);

export default TarifContainer;
